<template>
    <UniversalTable 
        ref="taskTable"
        :model="model"
        :pageName="page_name"
        tableType="tasks"
        autoHeight
        :endpoint="`/tasks/sprint/${sprint.id}/tasks_list/`"
        :params="queryParams"
        :openHandler="openTask"
        :main="false"
        taskType="task"
        extendDrawer
        :hash="false" />
</template>

<script>
import UniversalTable from '@/components/TableWidgets/UniversalTable'
export default {
    components: {
        UniversalTable
    },
    props: {
        sprint: {
            type: Object,
            required: true
        },
        actions: {
            type: Object,
            required: true
        },
        page_name: {
            type: String,
            required: true
        },
        model: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            queryParams: {
                task_type: 'task,stage'
            }
        }
    },
    methods: {
        openTask(item) {
            const query = Object.assign({}, this.$route.query)
            if(query.task && Number(query.task) !== item.id || !query.task) {
                query.task = item.id
                this.$router.push({query})
            }
        },
        tableReload() {
            this.$nextTick(() => {
                this.$refs.taskTable.reloadTableData()
            })
        }
    }
}
</script>